<template>
  <div>
    <v-expansion-panels
      v-model="filterExpansionPanel"
      accordion
      tile
    >
      <v-expansion-panel>
        <v-expansion-panel-header
          class=" px-4 py-3 backgroundAccent"
          style="min-height: 0;"
        >
          <span
            v-translate="{ count: filterCount }"
            translate-context="Page content (all todos)"
          >
            Filters (%{ count })
          </span>
        </v-expansion-panel-header>

        <v-expansion-panel-content>
          <v-divider />

          <div
            class="backgroundAccent pb-12"
          >
            <div>
              <div>
                <v-subheader
                  v-translate
                  translate-context="Page content (all todos)"
                >
                  Pick a space
                </v-subheader>

                <!-- space filter -->
                <div
                  :style="{
                    overflowX: 'scroll',
                    whiteSpace: 'nowrap',
                  }"
                >
                  <v-btn
                    v-for="space in spaces"
                    :key="space.id"
                    small
                    tile
                    depressed
                    class="mb-3"
                    height="48px"
                    :color="selectedSpace &&
                      selectedSpace.id === space.id ? 'backgroundAccent darken-2' : ''"
                    :dark="selectedSpace && selectedSpace.id === space.id"
                    :style="{
                      borderRight: '1px solid rgba(0, 0, 0, 0.14)',
                    }"
                    @click="() => selectSpace(space)"
                  >
                    {{ space.address }}
                  </v-btn>
                </div>
              </div>
              
              <div>
                <v-subheader
                  v-translate
                  translate-context="Page content (all todos)"
                >
                  Pick a team
                </v-subheader>

                <!-- team filter -->
                <div
                  :style="{
                    overflowX: 'scroll',
                    whiteSpace: 'nowrap',
                  }"
                >
                  <v-btn
                    v-for="team in teams"
                    :key="team.id"
                    small
                    tile
                    depressed
                    class="mb-3"
                    height="48px"
                    :color="selectedTeam && selectedTeam.id === team.id ? 'backgroundAccent darken-2' : ''"
                    :dark="selectedTeam && selectedTeam.id === team.id"
                    :style="{
                      borderRight: '1px solid rgba(0, 0, 0, 0.14)',
                    }"
                    @click="() => selectTeam(team)"
                  >
                    {{ team.name }}
                  </v-btn>
                </div>
              </div>
            </div>

            <div
              class="mt-12 d-flex justify-center align-content-space-around"
            >
              <v-btn
                :disabled="!selectedSpace && !selectedTeam"
                text
                x-large
                class="mr-2"
                @click="resetFilters"
              >
                <span
                  v-translate
                  translate-context="Reset button"
                >
                  Reset
                </span>
              </v-btn>

              <v-btn
                x-large
                color="success"
                class="ml-2"
                @click="filterExpansionPanel = false"
              >
                <span
                  v-translate
                  translate-context="Done button"
                >
                  Done
                </span>
              </v-btn>
            </div>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-slide-y-reverse-transition
      hide-on-leave
    >
      <v-skeleton-loader
        v-if="loading"
        type="expansionItem, expansionItem, expansionItem"
        :types="{
          expansionItem: 'list-item',
        }"
      />

      <div
        v-else-if="!assignedToProject($currentUser.id)"
        class="d-flex flex-column justify-center align-center pa-12 caption"
      >
        <span
          v-translate
          translate-context="Page content (all todos)"
          class="d-block subtitle-1"
        >
          You have not been added to this project.
        </span>

        <m-tip
          class="mt-4"
          :tip="$pgettext(
            'MAllTodosPage tip when user does not belong to any team',
            'Ask worksite management to add you to a team in this project.',
          )"
        />
      </div>

      <div
        v-else-if="!hasTodos"
        class="d-flex justify-center align-center pa-12 caption"
      >
        <span
          v-translate
          translate-context="Page content (all todos)"
          class="d-block subtitle-1"
        >
          Great job! All tasks done
        </span>

        <v-icon
          right
          color="secondary"
        >
          thumb_up
        </v-icon>
      </div>

      <div
        v-else
      >
        <v-expansion-panels
          accordion
          tile
        >
          <m-virtual-todos-list
            :todos="todos.dueTodos"
            header-classes="error"
            content-classes="errorBackground"
            :title="todosListTitleOverdue"
            :subtitle="selectedSpace ?
              `(${selectedSpace.address})` : null"
            :action-text="`${todos.dueTodosCount} kpl`"
          />

          <m-virtual-todos-list
            :todos="todos.todos"
            header-classes="info"
            content-classes="infoBackground"
            :title="todosListTitleCurrent"
            :subtitle="selectedSpace ?
              `(${selectedSpace.address})` : null"
            :action-text="`${todos.todosCount} kpl`"
          />

          <m-virtual-todos-list
            :todos="todos.futureTodos"
            header-classes="backgroundAccent darken-4"
            content-classes="backgroundAccent"
            :title="todosListTitleUpcoming"
            :subtitle="selectedSpace ?
              `(${selectedSpace.address})` : null"
            :action-text="`${todos.futureTodosCount} kpl`"
          />
        </v-expansion-panels>
      </div>
    </v-slide-y-reverse-transition>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import MVirtualTodosList from '../../components/MVirtualTodosList';

  export default {
    components: {
      MVirtualTodosList,
    },

    data: () => ({
      loading: false,
      selectedSpace: null,
      selectedTeam: null,
      filterExpansionPanel: false,
    }),

    computed: {
      ...mapGetters({
        todosByTakt: 'project/todos/todosByTakt',
        currentTact: 'project/tacts/currentTact',
        spaces: 'project/spaces/spaces',
        teams: 'project/teams/teams',
        assignedToProject: 'project/teamsUsers/assignedToProject',
      }),

      filterCount() {
        let count = 0;

        if (this.selectedSpace) count += 1;
        if (this.selectedTeam) count += 1;

        return count;
      },

      hasTodos() {
        const {
          dueTodos,
          todos,
          futureTodos,
        } = this.todos;

        if (Object.keys(dueTodos).length > 0) return true;
        if (Object.keys(todos).length > 0) return true;
        if (Object.keys(futureTodos).length > 0) return true; // eslint-disable-line

        return false;
      },

      todos() {
        const def = {
          dueTodosCount: 0,
          todosCount: 0,
          futureTodosCount: 0,
          dueTodos: {},
          todos: {},
          futureTodos: {},
        };

        const {
          taktIndex: currentTaktOrder,
        } = this.currentTact;

        const r = Object.keys(this.todosByTakt).reduce((acc, __taktOrder) => {
          const taktOrder = Number.parseInt(__taktOrder, 10);
          let data = this.todosByTakt[taktOrder];

          if (this.selectedSpace) {
            data = data.filter(d => d.spaceId === this.selectedSpace.id);
          }

          if (this.selectedTeam) {
            data = data.filter(d => d.teamId === this.selectedTeam.id);
          }

          if (taktOrder < currentTaktOrder) {
            return {
              ...acc,
              dueTodos: {
                ...acc.dueTodos,
                [taktOrder]: data,
              },
              dueTodosCount: acc.dueTodosCount + data.length,
            };
          }

          if (taktOrder === currentTaktOrder) {
            return {
              ...acc,
              todos: {
                ...acc.todos,
                [taktOrder]: data,
              },
              todosCount: acc.todosCount + data.length,
            };
          }

          if (taktOrder > currentTaktOrder) {
            return {
              ...acc,
              futureTodos: {
                ...acc.futureTodos,
                [taktOrder]: data,
              },
              futureTodosCount: acc.futureTodosCount + data.length,
            };
          }

          return acc;
        }, def);

        return r;
      },

      todosListTitleOverdue() {
        return this.$pgettext('Todos list title', 'Overdue');
      },

      todosListTitleCurrent() {
        return this.$pgettext('Todos list title', 'Current');
      },

      todosListTitleUpcoming() {
        return this.$pgettext('Todos list title', 'Upcoming');
      },
    },

    async created() {
      this.loading = true;

      await Promise.all([
        this.loadTodos({
          whereNotState: ['completed'],
        }),

        this.loadTacts(),
        this.loadCurrentTact(),

        // Needed for the cards
        this.loadUsers({ projectId: this.$projectId, groupBy: 'id' }),
        this.loadTeamsUsers(),

        // Needed for filters
        this.loadSpaces(),
        this.loadTeams(),
      ]);

      setTimeout(() => {
        this.loading = false;
      });
    },

    methods: {
      ...mapActions({
        loadTacts: 'project/tacts/loadTacts',
        loadCurrentTact: 'project/tacts/loadCurrentTact',
        loadTodos: 'project/todos/loadTodos',
        loadSpaces: 'project/spaces/loadSpaces',
        loadTeams: 'project/teams/loadTeams',
        loadUsers: 'users/loadUsers',
        loadTeamsUsers: 'project/teamsUsers/loadTeamsUsers',
      }),

      selectSpace(space) {
        if (this.selectedSpace && this.selectedSpace.id === space.id) {
          this.selectedSpace = null;
        } else {
          this.selectedSpace = space;
        }
      },

      selectTeam(team) {
        if (this.selectedTeam && this.selectedTeam.id === team.id) {
          this.selectedTeam = null;
        } else {
          this.selectedTeam = team;
        }
      },

      resetFilters() {
        this.selectedSpace = null;
        this.selectedTeam = null;
      },
    },
  };
</script>

<style lang="scss">
  .v-skeleton-loader__list-item > div {
    height: 16px;
  }
</style>
