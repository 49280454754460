<template>
  <v-expansion-panel>
    <v-expansion-panel-header
      :class="headerClasses"
      class="white--text elevation-6 px-4 py-3"
      style="z-index: 1; min-height: 0;"
    >
      {{ title }}

      <span
        v-if="subtitle"
        class="caption-xs ml-2"
      >
        {{ subtitle }}
      </span>

      <template v-slot:actions>
        <div>
          <span
            v-if="actionText"
            class="caption"
          >
            {{ actionText }}
          </span>

          <v-icon color="white">
            $expand
          </v-icon>
        </div>
      </template>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <div
        :class="contentClasses"
      >
        <RecycleScroller
          v-slot="{ item: todo }"
          class="pa-4"
          :items="items"
          :item-size="146"
          key-field="index"
          style="max-height: calc(100vh - 216px);"
        >
          <m-todos-feed-card
            ref="todoRef"
            :todo="todo"
            class="mb-2 mx-auto"
          />
        </RecycleScroller>
      </div>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
  import { RecycleScroller } from 'vue-virtual-scroller';
  import MTodosFeedCard from './MTodosFeedCard';

  import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';

  export default {
    components: {
      MTodosFeedCard,
      RecycleScroller,
    },

    props: {
      todos: {
        type: Object,
        required: true,
      },

      title: {
        type: String,
        default: '',
      },

      subtitle: {
        type: String,
        default: null,
      },

      headerClasses: {
        type: String,
        default: '',
      },

      contentClasses: {
        type: String,
        default: '',
      },

      actionText: {
        type: String,
        default: null,
      },
    },

    computed: {
      items() {
        const items = Object
          .values(this.todos)
          .map((todosArr, i) => todosArr.map((t, k) => ({
            ...t,
            index: `${i}-${k}`,
          })))
          .flat();

        return items;
      },
    },

    methods: {
      totalEstimatedDuration(tactOrder) { // eslint-disable-line
        const todos = this.todos[tactOrder];
        const minutesTotal = todos.reduce((acc, todo) => {
          if (todo.assignmentState === 'completed') return acc;
          return acc + todo.estimatedDuration;
        }, 0);
        const hours = (minutesTotal / 60);
        const rhours = Math.floor(hours);
        const minutes = (hours - rhours) * 60;
        const rminutes = Math.round(minutes);

        const hoursRendered = this.$gettextInterpolate(
          this.$ngettext('%{ rhours } hour', '%{ rhours } hours', rhours),
          { rhours },
        );

        const minutesRendered = this.$gettextInterpolate(
          this.$ngettext('%{ rminutes } minute', '%{ rminutes } minutes', rminutes),
          { rminutes },
        );

        return `${hoursRendered}<br>${minutesRendered}`;
      },
    },
  };
</script>

<style lang="scss">
  .v-expansion-panel-content__wrap {
    padding: 0;
  }
</style>
