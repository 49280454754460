var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-expansion-panels',{attrs:{"accordion":"","tile":""},model:{value:(_vm.filterExpansionPanel),callback:function ($$v) {_vm.filterExpansionPanel=$$v},expression:"filterExpansionPanel"}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:" px-4 py-3 backgroundAccent",staticStyle:{"min-height":"0"}},[_c('span',{directives:[{name:"translate",rawName:"v-translate",value:({ count: _vm.filterCount }),expression:"{ count: filterCount }"}],attrs:{"translate-context":"Page content (all todos)"}},[_vm._v(" Filters (%{ count }) ")])]),_c('v-expansion-panel-content',[_c('v-divider'),_c('div',{staticClass:"backgroundAccent pb-12"},[_c('div',[_c('div',[_c('v-subheader',{directives:[{name:"translate",rawName:"v-translate"}],attrs:{"translate-context":"Page content (all todos)"}},[_vm._v(" Pick a space ")]),_c('div',{style:({
                  overflowX: 'scroll',
                  whiteSpace: 'nowrap',
                })},_vm._l((_vm.spaces),function(space){return _c('v-btn',{key:space.id,staticClass:"mb-3",style:({
                    borderRight: '1px solid rgba(0, 0, 0, 0.14)',
                  }),attrs:{"small":"","tile":"","depressed":"","height":"48px","color":_vm.selectedSpace &&
                    _vm.selectedSpace.id === space.id ? 'backgroundAccent darken-2' : '',"dark":_vm.selectedSpace && _vm.selectedSpace.id === space.id},on:{"click":function () { return _vm.selectSpace(space); }}},[_vm._v(" "+_vm._s(space.address)+" ")])}),1)],1),_c('div',[_c('v-subheader',{directives:[{name:"translate",rawName:"v-translate"}],attrs:{"translate-context":"Page content (all todos)"}},[_vm._v(" Pick a team ")]),_c('div',{style:({
                  overflowX: 'scroll',
                  whiteSpace: 'nowrap',
                })},_vm._l((_vm.teams),function(team){return _c('v-btn',{key:team.id,staticClass:"mb-3",style:({
                    borderRight: '1px solid rgba(0, 0, 0, 0.14)',
                  }),attrs:{"small":"","tile":"","depressed":"","height":"48px","color":_vm.selectedTeam && _vm.selectedTeam.id === team.id ? 'backgroundAccent darken-2' : '',"dark":_vm.selectedTeam && _vm.selectedTeam.id === team.id},on:{"click":function () { return _vm.selectTeam(team); }}},[_vm._v(" "+_vm._s(team.name)+" ")])}),1)],1)]),_c('div',{staticClass:"mt-12 d-flex justify-center align-content-space-around"},[_c('v-btn',{staticClass:"mr-2",attrs:{"disabled":!_vm.selectedSpace && !_vm.selectedTeam,"text":"","x-large":""},on:{"click":_vm.resetFilters}},[_c('span',{directives:[{name:"translate",rawName:"v-translate"}],attrs:{"translate-context":"Reset button"}},[_vm._v(" Reset ")])]),_c('v-btn',{staticClass:"ml-2",attrs:{"x-large":"","color":"success"},on:{"click":function($event){_vm.filterExpansionPanel = false}}},[_c('span',{directives:[{name:"translate",rawName:"v-translate"}],attrs:{"translate-context":"Done button"}},[_vm._v(" Done ")])])],1)])],1)],1)],1),_c('v-slide-y-reverse-transition',{attrs:{"hide-on-leave":""}},[(_vm.loading)?_c('v-skeleton-loader',{attrs:{"type":"expansionItem, expansionItem, expansionItem","types":{
        expansionItem: 'list-item',
      }}}):(!_vm.assignedToProject(_vm.$currentUser.id))?_c('div',{staticClass:"d-flex flex-column justify-center align-center pa-12 caption"},[_c('span',{directives:[{name:"translate",rawName:"v-translate"}],staticClass:"d-block subtitle-1",attrs:{"translate-context":"Page content (all todos)"}},[_vm._v(" You have not been added to this project. ")]),_c('m-tip',{staticClass:"mt-4",attrs:{"tip":_vm.$pgettext(
          'MAllTodosPage tip when user does not belong to any team',
          'Ask worksite management to add you to a team in this project.'
        )}})],1):(!_vm.hasTodos)?_c('div',{staticClass:"d-flex justify-center align-center pa-12 caption"},[_c('span',{directives:[{name:"translate",rawName:"v-translate"}],staticClass:"d-block subtitle-1",attrs:{"translate-context":"Page content (all todos)"}},[_vm._v(" Great job! All tasks done ")]),_c('v-icon',{attrs:{"right":"","color":"secondary"}},[_vm._v(" thumb_up ")])],1):_c('div',[_c('v-expansion-panels',{attrs:{"accordion":"","tile":""}},[_c('m-virtual-todos-list',{attrs:{"todos":_vm.todos.dueTodos,"header-classes":"error","content-classes":"errorBackground","title":_vm.todosListTitleOverdue,"subtitle":_vm.selectedSpace ?
            ("(" + (_vm.selectedSpace.address) + ")") : null,"action-text":((_vm.todos.dueTodosCount) + " kpl")}}),_c('m-virtual-todos-list',{attrs:{"todos":_vm.todos.todos,"header-classes":"info","content-classes":"infoBackground","title":_vm.todosListTitleCurrent,"subtitle":_vm.selectedSpace ?
            ("(" + (_vm.selectedSpace.address) + ")") : null,"action-text":((_vm.todos.todosCount) + " kpl")}}),_c('m-virtual-todos-list',{attrs:{"todos":_vm.todos.futureTodos,"header-classes":"backgroundAccent darken-4","content-classes":"backgroundAccent","title":_vm.todosListTitleUpcoming,"subtitle":_vm.selectedSpace ?
            ("(" + (_vm.selectedSpace.address) + ")") : null,"action-text":((_vm.todos.futureTodosCount) + " kpl")}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }